"use client";
import Link from "next/link";
import {
    AvatarImage,
    AvatarFallback,
    Avatar,
} from "@/components/shared/avatar";
import { Input } from "@/components/shared/input";
import { Button } from "@/components/shared/button";
import {
    BarChartIcon,
    MountainIcon,
    CalendarIcon,
    HeartIcon,
} from "@/components/svg/main_page";

export default function Index() {
    return (
        <div className="flex flex-col min-h-screen">
            <header className="px-4 lg:px-6 h-14 flex items-center">
                <Link className="flex items-center justify-center" href="#">
                    <MountainIcon className="h-6 w-6" />
                    <span className="sr-only">Pichuka</span>
                </Link>
                <nav className="ml-auto flex flex-row gap-5 sm:gap-6">
                    <Link
                        className="inline-flex items-center justify-center rounded-md bg-gray-900 px-3 py-1 text-normal font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                        href="/users/login"
                    >
                        Login
                    </Link>
                    <Link
                        className=" inline-flex items-center justify-center text-sm font-medium hover:underline underline-offset-4"
                        href="#"
                    >
                        Features
                    </Link>
                    <Link
                        className="inline-flex items-center justify-center text-sm font-medium hover:underline underline-offset-4"
                        href="#"
                    >
                        Pricing
                    </Link>
                    <Link
                        className="inline-flex items-center justify-center text-sm font-medium hover:underline underline-offset-4"
                        href="#"
                    >
                        About
                    </Link>
                    <Link
                        className="inline-flex items-center justify-center text-sm font-medium hover:underline underline-offset-4"
                        href="#"
                    >
                        Contact
                    </Link>
                </nav>
            </header>
            <main className="flex-1">
                <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48">
                    <div className="container px-4 md:px-6">
                        <div className="flex flex-col items-center space-y-6 text-center">
                            <div className="space-y-4">
                                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl mb-4">
                                    Manage your Instagram with ease
                                </h1>
                                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400 mt-2">
                                    Pichuka offers a suite of tools to help you
                                    make the most of your Instagram account.
                                    Schedule posts, track analytics, and
                                    increase engagement all in one place.
                                </p>
                            </div>
                            <div className="space-x-4">
                                <Link
                                    className="inline-flex items-center justify-center rounded-md bg-gray-900 px-6 py-3 text-base font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                                    href="/users/register"
                                >
                                    Get Started Now
                                </Link>
                                <Link
                                    className="inline-flex h-9 items-center justify-center rounded-md border  border-gray-200 bg-white px-4 py-2 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50  dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus-visible:ring-gray-300"
                                    href="#"
                                >
                                    Learn more
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800">
                    <div className="container px-4 md:px-6">
                        <div className="grid items-center gap-6 lg:grid-cols-3 lg:gap-12 xl:grid-cols-3">
                            <div className="flex flex-col justify-center space-y-4">
                                <div className="space-y-2">
                                    <BarChartIcon className="h-12 w-12 mx-auto text-gray-900 dark:text-gray-50" />
                                    <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl text-center">
                                        Analytics
                                    </h2>
                                    <p className="max-w-[600px] text-gray-500 md:text-lg/relaxed lg:text-base/relaxed xl:text-lg/relaxed dark:text-gray-400 text-center mx-auto">
                                        Track your Instagram performance and
                                        gain insights into your audience and how
                                        they engage with your content.
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center space-y-4">
                                <div className="space-y-2">
                                    <CalendarIcon className="h-12 w-12 mx-auto text-gray-900 dark:text-gray-50" />
                                    <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl text-center">
                                        Scheduling
                                    </h2>
                                    <p className="max-w-[600px] text-gray-500 md:text-lg/relaxed lg:text-base/relaxed xl:text-lg/relaxed dark:text-gray-400 text-center mx-auto">
                                        Schedule your posts in advance and
                                        publish them automatically at the right
                                        time.
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center space-y-4">
                                <div className="space-y-2">
                                    <HeartIcon className="h-12 w-12 mx-auto text-gray-900 dark:text-gray-50" />
                                    <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl text-center">
                                        Engagement
                                    </h2>
                                    <p className="max-w-[600px] text-gray-500 md:text-lg/relaxed lg:text-base/relaxed xl:text-lg/relaxed dark:text-gray-400 text-center mx-auto">
                                        Boost your engagement by responding to
                                        comments faster and starting
                                        conversations with followers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="w-full py-12 md:py-24 lg:py-32">
                    <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6 lg:gap-10">
                        <div className="space-y-3">
                            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                                What our customers say
                            </h2>
                            <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                                Don&apos;t just take our word for it, hear what
                                our customers have to say.
                            </p>
                        </div>
                        <div className="grid w-full grid-cols-1 lg:grid-cols-2 items-center justify-center gap-8 lg:gap-12 [&>img]:mx-auto">
                            <div className="flex flex-col items-center gap-4">
                                <Avatar className="w-16 h-16">
                                    <AvatarImage
                                        alt="User testimonial"
                                        src="/placeholder-user.jpg"
                                    />
                                    <AvatarFallback>JP</AvatarFallback>
                                </Avatar>
                                <blockquote className="text-lg font-semibold leading-snug lg:text-xl lg:leading-normal xl:text-2xl">
                                    “Pichuka has been a game changer for our
                                    social media strategy. The analytics and
                                    scheduling features have saved us so much
                                    time.”
                                </blockquote>
                                <div>
                                    <div className="font-semibold">
                                        Jules Winnfield
                                    </div>
                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                        CEO, Acme Inc
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center gap-4">
                                <Avatar className="w-16 h-16">
                                    <AvatarImage
                                        alt="User testimonial"
                                        src="/placeholder-user.jpg"
                                    />
                                    <AvatarFallback>JP</AvatarFallback>
                                </Avatar>
                                <blockquote className="text-lg font-semibold leading-snug lg:text-xl lg:leading-normal xl:text-2xl">
                                    “The engagement tools have helped us connect
                                    with our audience in a more meaningful way.
                                    Highly recommend Pichuka!”
                                </blockquote>
                                <div>
                                    <div className="font-semibold">
                                        Mia Wallace
                                    </div>
                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                        Founder, Wallace Enterprises
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
                <p className="text-xs text-gray-500 dark:text-gray-400">
                    © 2024 Pichuka. All rights reserved.
                </p>
                <nav className="sm:ml-auto flex gap-4 sm:gap-6">
                    <Link
                        className="text-xs hover:underline underline-offset-4"
                        href="/terms.html"
                    >
                        Terms of Service
                    </Link>
                    <Link
                        className="text-xs hover:underline underline-offset-4"
                        href="/privacy.html"
                    >
                        Privacy
                    </Link>
                </nav>
                <div className="mt-4 w-full max-w-sm space-y-2">
                    <form className="flex space-x-2">
                        <Input
                            className="max-w-lg flex-1"
                            placeholder="Enter your email"
                            type="email"
                        />
                        <Button type="submit">Subscribe</Button>
                    </form>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                        Subscribe to get notified about new features and
                        updates.
                    </p>
                </div>
            </footer>
        </div>
    );
}
